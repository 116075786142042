
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// head004 menu

(function ($) {

    "use strict";

    var self,
        burger,
        nav,
        navContent,
        overlay;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function head004() {

        self    = this;
        burger  = '.burger';
        nav     = '.head004';
        navContent = '.desktop-navigation';
        overlay = '#navigation-overlay';

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    head004.prototype.init = function () {

        self = this;

        self.addEventHandler();

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    head004.prototype.addEventHandler = function () {

        self = this;

        $(burger).on('click', function(event) {
            event.preventDefault();
            $(nav).toggleClass('active');
            $(navContent).toggleClass('active');
            $(overlay).toggleClass('active');
            $(burger).toggleClass('active');
        });

        $('.desktop-navigation .link').on('click', function(event) {
            console.log("Yeah!");
            $(nav).removeClass('active');
            $(navContent).removeClass('active');
            $(overlay).removeClass('active');
            $(burger).removeClass('active');
        });

        $(overlay).on('click', function(event) {
            event.preventDefault();
            $(nav).removeClass('active');
            $(navContent).removeClass('active');
            $(overlay).removeClass('active');
            $(burger).removeClass('active');
        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    this.head004 = new head004();

}.bind(Deckperfo, jQuery)());

