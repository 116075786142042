
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// info001 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function info001() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    info001.prototype.init = function () {

        var elem = document.querySelector('.info001 .carousel');

        if( elem ){

            var flkty = new Flickity( elem, {
                'cellAlign': 'left',
                'contain': true,
                'wrapAround': true,
                'prevNextButtons': true,
                'pageDots': false,
                'imagesLoaded': true,
                'autoPlay': 3575
            });

        }

    };

    this.info001 = new info001();

}.bind(Deckperfo, jQuery)());