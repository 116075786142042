

// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// popp001 Module

(function ($) {

    "use strict";

    var self,
        $popup,
        $overlay,
        trigger,
        closer,
        $content,
        form;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function popp001() {

        self        = this;
        $popup      = $('.popp001');
        $overlay    = $('.popup-overlay');
        trigger     = '[data-trigger-popup]',
        closer      = '[data-close-popup]';
        $content    = $('[data-content]');

        // Newsletter
        form        = '.js-newsletter';

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    popp001.prototype.init = function () {

        self = this;

        self.addEventHandler();

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    popp001.prototype.addEventHandler = function () {

        self = this;

        $('body').on('click', trigger, function(event) {

            event.preventDefault();

            var $publication = $(this);

            self.closePopup();
            self.showPopup( $publication );

        });

        $popup.on('click', closer, function(event) {

            event.preventDefault();

            self.closePopup();

        });

        $overlay.on('click', function(event) {

            event.preventDefault();

            self.closePopup();

        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    popp001.prototype.showPopup = function ( $publication ) {

        self = this;

        $.ajax({
            type: 'POST',
            url: $publication.attr('href'),
            data: 'p=1',
            cache: false,
            beforeSend: function(){

                $popup.find('.content').remove();
                $popup.append('\
                    <div class="content">\
                    <div class="cssload-container">\
                        <div class="cssload-double-torus"></div>\
                    </div>\
                    </div>'
                );
                $popup.removeClass('non-visible');
                $overlay.removeClass('non-visible');
            }
        }).done(function(response) {

            $popup.find('.content').remove();
            $popup.append(response);

        });

    };

    popp001.prototype.closePopup = function () {

        self = this;

        $popup.addClass('non-visible');
        $overlay.addClass('non-visible');

    };

    this.popp001 = new popp001();

}.bind(Deckperfo, jQuery)());