/**
 * Avoid `console` errors in browsers that lack a console.
 * ===========================================
 *
 */
;(function() {
    'use strict';
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());


// Namespacing

window.Deckperfo = window.Deckperfo  || {};


/**
 *
 * ===========================================
 *
 */



/**
 * BgCoverIt Module
 * ===========================================
 *
 */
(function ($) {

    "use strict";

    var self,
        $bgCoverIt;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BgCoverIt() {

        self        = this;
        $bgCoverIt  = $('[data-bg-cover-it]');

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BgCoverIt.prototype.init = function () {

        $.each( $bgCoverIt, function(index, val) {

            var $img = $(val).find('[data-bg-cover-img]'),
                img_src = $img.attr('src');

            $img.remove();

            $(val).css('background-image', 'url("'+img_src+'")');

        });

    };

    this.BgCoverIt = new BgCoverIt();

}.bind(Deckperfo, jQuery)());



/**
 * SCRIPT GoTo();
 * ===========================================
 *
 */
$(document).ready(function() {

  'use strict';

    function init(){

        initEvents();

    }

    function initEvents(){

        /*goTo*/
        $( '[data-goto]' ).on( 'click', function(){

            var nextSection = $( this ).attr( 'data-goto' );

            $( 'html, body' ).animate({
                scrollTop: $( '#'+nextSection ).offset().top - 85
            }, 855);

            return false;

        });

    }

  init();

});