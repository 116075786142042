// Namespacing

window.Deckperfo = window.Deckperfo || {};

// Launch modules

(function ($) {

  'use strict';

  var app = this;

  $(document).ready(function () {

    // Global js pluggins
    app.BgCoverIt.init();

    // Component's core js modules
    app.BlogGridList.init();
    app.head004.init();
    app.info001.init();
    app.foot003.init();
    app.popp001.init();
  });


}.bind(Deckperfo, jQuery)());