
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// BlogGridList Module

(function ($) {

    "use strict";

    var self,
        $paginator,
        pager,
        grid,
        loader,
        $filters;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlogGridList() {

        self = this;
        $paginator = $('.blog-ajax-shoMore');
        grid = $('#grid');
        loader = $('.js-loader');
        $filters = $('.post-filters a');
        pager = 1;
    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlogGridList.prototype.init = function () {

        if( window.location.hash ) {
            var hash = window.location.hash;
            var filter = hash.replace('#', '');
            console.log( filter );
            if( filter && filter != '*' ) {
              setTimeout( function(){ $('[data-filter="'+filter+'"]').trigger('click'); }, 555 );
            }

          }

        grid.imagesLoaded().progress(function(){

            grid.isotope();

        });

        $paginator.on('click', function(e){

            e.preventDefault();
            var url = $(this).attr('href');
            self.postsPage(url);
        });

        $filters.on('click', function(event) {

            if( $('body').hasClass('home') ) {
                return true;
            }

            event.preventDefault();

            $filters.removeClass('active');

            $(this).addClass('active');

            var type = $(this).data('filter');

            grid.isotope({ filter: '[data-filter~="'+type+'"]' });

            if( type === '*' ){
                grid.isotope({ filter: '*' });
            }

            grid.isotope('layout');

            $('#vrai-ou-faux .main-cta').attr('href', $('#vrai-ou-faux .main-cta').data('href') + '/#' + type);

            self.toogleShowMoreBtn();

        });

        self.toogleShowMoreBtn();
    };


    BlogGridList.prototype.postsPage = function(url) {

        pager++;

        $.ajax({
            type: 'POST',
            url: url,
            data: 'page=' + pager,
            cache: false,
            beforeSend: function(){
                $paginator.addClass('loader');
            }
        }).done(function(response) {

            $paginator.removeClass('loader');

            var items = $(response).find('.grid-item'),
                hasMorePage = $(response).find('input[name="hasMorePage"]');

            if ( ! hasMorePage.val() ) {

                $paginator.remove();
            }

            grid.isotope('insert', items);

            //Layout Isotope after images are loaded and insert happens
            grid.imagesLoaded().progress( function() {
                grid.isotope('layout');
            });
        });
    };

    BlogGridList.prototype.toogleShowMoreBtn = function() {
        var currentFilter = $('.post-filters a.active').data('filter');
        if( currentFilter === '*'){
            $paginator.fadeIn();
        }else{
            $paginator.fadeOut();
        }
    };

    this.BlogGridList = new BlogGridList();

}.bind(Deckperfo, jQuery)());