
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// foot003 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function foot003() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    foot003.prototype.init = function () {

        var mapHolder = $('#map');

        var marker_Lat = mapHolder.data( "marker-lat" );
        var marker_Lng =  mapHolder.data( "marker-long" );

        var mapcenter_Lat = mapHolder.data( "mapcenter-lat" );
        var mapcenter_Lng =  mapHolder.data( "mapcenter-long" );

        var app_adress =  mapHolder.data( "adress" );

        var isDraggable = true;

        var mapSettings = {
            linkToMap : 'https://www.google.ca/maps/dir/\'\'/'+encodeURIComponent(app_adress)+'/',
            pinImage : '/assets/images/google-marker.png'
        };

        if( $(window).width() <= 799 ){
            mapcenter_Lat = marker_Lat;
            mapcenter_Lng =  marker_Lng;
            isDraggable = false;
        }

        if (mapHolder.length) {

            //MAP
            var initialize = function() {
                var mapOptions = {
                    zoom: 15,
                    scrollwheel: false,
                    overviewMapControl: false,
                    panControl: false,
                    draggable : isDraggable,
                    disableDefaultUI: true,
                    rotateControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    center: new google.maps.LatLng( mapcenter_Lat, mapcenter_Lng ),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
                };

                var map = new google.maps.Map(document.getElementById('map'),mapOptions);

                var myLatLng = new google.maps.LatLng( marker_Lat, marker_Lng );

                var hoffmanMarker = new google.maps.Marker({
                  position: myLatLng,
                  map: map,
                  icon: mapSettings.pinImage
              });
              google.maps.event.addListener(hoffmanMarker, 'click', function () {
                  window.open(mapSettings.linkToMap, '_blank');
              });
               //Retrive the center location
              // google.maps.event.addListener(map, "center_changed", function() {
              //   console.log( map.getCenter().toUrlValue() );
              // });
            };

            google.maps.event.addDomListener(window, 'load', initialize);

        }

    };

    this.foot003 = new foot003();

}.bind(Deckperfo, jQuery)());